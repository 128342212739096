export const advo = [
  {
    image: 'Abs-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Abs.pdf',
  },
  {
    image: 'Active-Mobility-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Active-Mobility.pdf',
  },
  {
    image: 'At-Home-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/At-Home.pdf',
  },
  {
    image: 'Core-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Core.pdf',
  },
  {
    image: 'Elbows-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Elbows.pdf',
  },
  {
    image: 'Foundation-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Foundation.pdf',
  },
  {
    image: 'Functional-Fitness-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Functional-Fitness.pdf',
  },
  {
    image: 'Handstands-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Handstands.pdf',
  },
  {
    image: 'Hip-Mobility-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Hip-Mobility.pdf',
  },
  {
    image: 'Jefferson-Curl-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Jefferson-Curl.pdf',
  },
  {
    image: 'Member-Spotlight-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Member-Spotlight.pdf',
  },
  {
    image: 'Muscle-Ups-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Muscle-Ups.pdf',
  },
  {
    image: 'Neck-Traps-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Neck-Traps.pdf',
  },
  {
    image: 'Plateaus-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Plateaus.pdf',
  },
  {
    image: 'Progressions-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Progressions.pdf',
  },
  {
    image: 'Shoulders-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Shoulders.pdf',
  },
  {
    image: 'Sitting-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Sitting.pdf',
  },
  {
    image: 'Slow-Down-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Slow-Down.pdf',
  },
  {
    image: 'Stretch-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Stretch.pdf',
  },
  {
    image: 'Where-to-Start-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Where-to-Start.pdf',
  },
  {
    image: 'Wrists-icon.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/Wrists.pdf',
  },
]
