export const suggestedSecondary = {
  core: {
    Beginner:'Core strength is essential so we’ll start you with introductory core work.',
    IntermediateOne: 'Your core is similar to where most people start. ',
    IntermediateTwo: 'Good core strength. To progress to harder exercises, you’ll need more mobility. ',
    AdvancedOne: 'Nice work, core conqueror! Your focus on full-body strength is opening doors to some cool tricks.',
    AdvancedTwo: 'Shoot dang, your core is strong. You’re ready to exceed your own expectations. '
  },
  strength: {
    Beginner:'We’ll begin with gentle strength work that rejuvenates your movement.',
    IntermediateOne: 'You are ready to get strong. We’ll shift your focus to increase strength building.',
    IntermediateTwo: 'Good core strength. To progress to harder exercises, you’ll need more mobility.',
    AdvancedOne: 'You’ve not neglected your mobility. You’re a prime candidate for what is possible with GymFit.',
    AdvancedTwo: 'Your strength is impressive. Now let’s increase it and use it to do cool stuff.'
  },
  mobility: {
    Beginner:'We won’t sugar coat it, mobility should be your training priority right now.',
    IntermediateOne: 'Your mobility is coming along, with room to improve. We’ve got what you need for that.',
    IntermediateTwo: 'Your mobility is good and we’re about to accelerate it further.',
    AdvancedOne: 'Nice! Your mobility is impressive, not only in range but in strength.',
    AdvancedTwo: 'Outstanding! Your focus on mobility is about to pay off in a big way.'
  }
}
