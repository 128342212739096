import Axios from 'axios'
import moment from 'moment-timezone'
import _ from 'lodash'
import * as Sentry from "@sentry/react";

import { getCalanderDate } from '../../Components/UtilComponents/GetCurrentWeek'
import * as actionTypes from '../Action/actionTypes';
import { AxiosConfig } from '../util'
import { showToast } from './calendarActions';
import { SET_PROGRESSION } from './LegacyAction';

export const getBeginnerLevel = () => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  Axios(AxiosConfig('get', `/myschedule/beginner/view/weekly/users/${UserId}`, webToken)).then(res => {
    dispatch({
      type: actionTypes.SET_LEVELS,
      payload:{ userSchedule: res.data}
    })
  })

}


export const selectBeginenrWorkout = (dateIndex, dateKey, workoutId) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateIndex];


  Axios(AxiosConfig('POST', `/myschedule/beginner/users/${UserId}/workouts/${workoutId}?date=${date}`, webToken)).then(res => {
    dispatch({
      type: actionTypes.GET_WORKOUT,
      payload: {
        userSchedule: {
          ...userSchedule,
          [dateKey] : res.data
        }
      }
    })
  }).catch(error => {
    Sentry.captureException(error);
  });
}


export const logAllBeginnerWorkout = (dateIndex, dateKey, courseIds = []) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone, levelId } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateIndex];

  const levelsOneToFour = between(levelId, 1, 4);

  let newWorkoutData = levelsOneToFour ? userSchedule[dateKey] : userSchedule[dateKey].classesList;

  let courseIdArray = courseIds.length > 0
    ? courseIds
    : newWorkoutData.filter(w => w.isLogged === false).map(w => w.classId);


  Axios(AxiosConfig('POST', `/class-log/beginner/users/${UserId}?loggedDate=${date}`, webToken, { data: courseIdArray })).then(res => {
    courseIdArray.forEach(c => {
      const index = newWorkoutData.findIndex(w => w.classId === c);
      newWorkoutData[index].isLogged = true;
    })

    let action = levelsOneToFour
      ? {
        type: actionTypes.LOG_NON_LEGACY_WORKOUT,
        payload: {
          userSchedule: {
            ...userSchedule,
            [dateKey]: [...newWorkoutData]
          }
        }
      }
      : {
        type: actionTypes.LOG_NON_LEGACY_WORKOUT,
        payload: {
          userSchedule: {
            ...userSchedule,
            [dateKey]: {
              ...userSchedule[dateKey],
              classesList: newWorkoutData
            }
          }
        }
      }

    dispatch(action)
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const removeBeginnerWorkoutLog = (dateIndex, dateKey, courseId) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone, levelId } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateIndex];

  const levelsOneToFour = between(levelId, 1, 4);

  let newWorkoutData = levelsOneToFour ? userSchedule[dateKey] : userSchedule[dateKey].classesList;

  Axios(AxiosConfig('DELETE', `/class-log/beginner/users/${UserId}?loggedDate=${date}&classId=${courseId}`, webToken)).then(res => {
    const index = newWorkoutData.findIndex(w => w.classId === courseId);
    newWorkoutData[index].isLogged = false;
    let action = levelsOneToFour
      ? {
        type: actionTypes.REMOVE_LOG_NON_LEGACY_WORKOUT,
        payload: {
          userSchedule: {
            ...userSchedule,
            [dateKey]: [...newWorkoutData]
          }
        }
      }
      : {
        type: actionTypes.REMOVE_LOG_NON_LEGACY_WORKOUT,
        payload: {
          userSchedule: {
            ...userSchedule,
            [dateKey]: {
              ...userSchedule[dateKey],
              classesList: newWorkoutData
            }
          }
        }
      }

    dispatch(action)
  }).catch(error => {
    Sentry.captureException(error);
  });
}


const levelObj = {
  0: {
    userLevel: 'Beginner',
    levelId: 0
  },
  1: {
    userLevel: 'Intermediate One',
    levelId: 1
  },
  2: {
    userLevel: 'Intermediate Two',
    levelId: 2
  },
  3: {
    userLevel: 'Advanced One',
    levelId: 3
  },
  4: {
    userLevel: 'Advanced Two',
    levelId: 4
  },
  9: {
    userLevel: 'White Board',
    levelId: 9
  },
  10: {
    userLevel: 'Build Your Own',
    levelId: 10
  }
}

function between(x, min, max) {
  return x >= min && x <= max;
}


export const continutePreviosLevel = (levelId) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  Axios(AxiosConfig('put', `/myschedule/choose/continue/level/${levelId}/users/${UserId}`, webToken))
    .then(res => {
      dispatch({
        type: actionTypes.CONTINUE_USER_LEVEL,
        payload: {
          ...levelObj[levelId],
          showAllOpen: between(levelId, 1, 4),
        }
      })
    }).then(() => {
      // this ensures that if a user selects the same level again after doing something in their schedule like clearing a day etc.
      // it will reload the data
      if (between(levelId, 1, 4)) {
        dispatch(getLevelPLan());
      }
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const setLevelPath = (leveld, isCallback = false, workoutOrPlanId = 0) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId , timezone, userLevel} = state.login;
  const currentDate = moment().tz(timezone).format('YYYY-MM-DD');

  if (userLevel === "New User" && leveld === 0) {
    workoutOrPlanId = 1;
  }

  Axios(AxiosConfig('post', `/myschedule/choose/level/${leveld}/users/${UserId}?workoutOrPlanId=${workoutOrPlanId}&date=${currentDate}`, webToken))
    .then(res => {
      dispatch({
        type: actionTypes.SET_USER_LEVEL,
        payload: {
          ...levelObj[leveld],
          showAllOpen: between(leveld, 1, 4),
        }
      })
    }).then(() => {
      if (isCallback) {
        isCallback();
      }
      // this ensures that if a user selects the same level again after doing something in their schedule like clearing a day etc.
      // it will reload the data
      if (between(leveld, 1, 4)) {
        dispatch(getLevelPLan());
      }
    }).catch(error => {
      Sentry.captureException(error);
    });
}

const processUserWorkout = workout => {
  if (workout.type === 'Class') {
    let workOutData = { ...workout.workout, showRefresh: showRefreshArray.includes( workout.workout.trainingType) };

    delete workout.workout;

    return {
      ...workout,
      ...workOutData
    }
  }

  if (workout.type === 'Program') {
    return proccesLegacyCourses(workout);
  }
}

export const proccesLegacyCourses = (data) => {
  const {workout} = data;
  let levelKeys = Object.keys(workout);
  let chosenProgs = [];
  let groupName;

  const idToClassName = {
    59207: 'Foundation Core',
    59219: 'Foundation Upper Body',
    59213: 'Foundation Lower Body'
  }

  levelKeys.forEach(lKey => {
    let progKeys = Object.keys(workout[lKey]);

    progKeys.forEach(pKey => {
      let progessions = workout[lKey][pKey];
      groupName = checkGroup(progessions);

      progessions.forEach(prog => {
        chosenProgs = [...chosenProgs, {...prog, section:pKey, levelKey: lKey } ]
      })
    })
  });

  if (!groupName) {
    groupName = idToClassName[data.classId]
  }

  return {chosenProgs: chosenProgs, isLegacy: true, category:groupName };
}

const checkGroup = workouts => {
  const first = workouts[0].group;
  return workouts.every(item => item.group === first) ? first : '';
}

const showRefreshArray = ['Warm-Up', 'Mobility', 'Stretch'];

export const getLevelPLan = () => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, levelId } = state.login;

  Axios(AxiosConfig('GET', `/myschedule/levels/view/weekly/users/${UserId}/levels/${levelId}`, webToken))
    .then(res => {
      let workoutSchedule = res.data ? _.cloneDeep(res.data) : {};
      let keys = Object.keys(workoutSchedule);

      keys.forEach(k => {
        let workouts = workoutSchedule[k] ? workoutSchedule[k] : [] ;
        workoutSchedule[k] = workouts.map(processUserWorkout);
      })

      dispatch({
        type: actionTypes.SET_LEVELS,
        payload: { userSchedule: workoutSchedule}
      })
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const clearOutDay = (dayIndex, isBeginner = false) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;
  const { userSchedule } = state.levels;

  const userScheduleKey = Object.keys(userSchedule)[dayIndex];

  let endPoint = isBeginner
    ? `/myschedule/beginner/users/${UserId}/dayIndex/${dayIndex + 1}`
    : `/myschedule/levels/users/${UserId}/dayIndex/${dayIndex + 1}`;

  Axios(AxiosConfig('DELETE', endPoint, webToken))
    .then(res => {
      let newUserSchedule = _.cloneDeep(userSchedule);
      newUserSchedule[userScheduleKey] = [];

      dispatch({
        type: actionTypes.CLEAR_DAY_LEVELS,
        payload: { userSchedule: newUserSchedule }
      })
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const clearOutDayBeginner = (dayIndex) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;
  const { userSchedule } = state.levels;

  const userScheduleKey = Object.keys(userSchedule)[dayIndex];

  Axios(AxiosConfig('DELETE', `/myschedule/beginner/users/${UserId}/dayIndex/${dayIndex + 1}`, webToken))
    .then(res => {
      let newUserSchedule = _.cloneDeep(userSchedule);

      newUserSchedule[userScheduleKey] = {
        ...newUserSchedule[userScheduleKey],
        classesList: null,
        scheduleId: null
      };

      dispatch({
        type: actionTypes.CLEAR_DAY_LEVELS,
        payload: { userSchedule: newUserSchedule }
      })
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const generateWorkoutLevels = (workoutId, dateIndex, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, levelId , timezone} = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateIndex];

  Axios(AxiosConfig('POST', `/myschedule/levels/users/${UserId}/level/${levelId}/workout/${workoutId}?date=${date}`, webToken)).then(res => {
    let workoutSchedule = res.data ? res.data : [];
    let newUserSchedule = _.cloneDeep(userSchedule);

    newUserSchedule[dateKey] = workoutSchedule.map(processUserWorkout);

    dispatch({
      type: actionTypes.GET_WORKOUT,
      payload: {
        userSchedule: newUserSchedule
      }
    })
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const refreshWMS = (scheduleId, trainingType, dateIndex, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, levelId, timezone } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateIndex];

  const switchTrainingType = {
    "Mobility": 'mobility',
    "Warm-Up": 'warmup',
    "Stretch": 'stretch'
  };

  Axios(AxiosConfig(
    'PUT',
    `/myschedule/levels/users/${UserId}/schedule/${scheduleId}/level/${levelId}?date=${date}&trainingType=${switchTrainingType[trainingType]}`,
    webToken
  )).then(res => {
    let workout = res.data;
    workout = processUserWorkout(workout);

    let newSchedule = _.cloneDeep(userSchedule);
    let dayToUpDate = newSchedule[dateKey];

    const index = dayToUpDate.findIndex(w => w.scheduleId === scheduleId);

    dayToUpDate[index] = workout;
    newSchedule[dateKey] = dayToUpDate;

    dispatch({
      type: actionTypes.GET_WORKOUT,
      payload: {
        userSchedule: newSchedule
      }
    })
  }).catch(error => {
    Sentry.captureException(error);
  });
}

// New LegacyActions
export const ManageDificulty = (workoutIndex, dateKey, dateKeyIndex, exerciseId, type) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateKeyIndex];

  const legacyWorkout = userSchedule[dateKey][workoutIndex];
  const courseName = legacyWorkout.category.replace('Foundation ', '');

  Axios(AxiosConfig('put', `/workout-service/users/${UserId}/difficulty/${type}/?workoutType=${courseName}&exerciseId=${exerciseId}&date=${date}`, webToken))
    .then(res => {
      dispatch(getLevelPLan())
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const LogLegacy = (exerciseId, mobilityStatus, autoProg, steps, logList, dateKeyIndex, dateKey, workoutIndex) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateKeyIndex];

  const legacyWorkout = userSchedule[dateKey][workoutIndex];
  const courseName = legacyWorkout.category.replace('Foundation ', '');

  let body = {
    data: {
      userId: UserId,
      date: date,
      exerciseId: exerciseId,
      imStatus: mobilityStatus,
      autoprogress: autoProg,
      notes: null,
      masterySets: steps,
      setsAndRepsDTOList: logList
    }
  }

  Axios(AxiosConfig('post', `/workout-service/programs/users/${UserId}/logging?workoutType=${courseName}`, webToken, body))
    .then(res => dispatch(getLevelPLan())).catch(error => {
      Sentry.captureException(error);
    });
}

export const SaveNotesLevels = (notes, exerciseId, masterySteps, dateKeyIndex) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const date = getCalanderDate(timezone)[dateKeyIndex];

  let body = {
    data: {
      userId: UserId,
      date: date,
      exerciseId: exerciseId,
      notes: notes,
      masterySets: {
        masterySetId: masterySteps.masterySetId,
        sets: masterySteps.sets,
        repsOrSecs: masterySteps.repsOrSecs
      },
      setsAndRepsDTOList: []
    }
  }

  Axios(AxiosConfig('post', `/program-log/notes/users/${UserId}`, webToken, body))
    .then(res => {
      dispatch(showToast('Your notes have been saved.', 'success'))
      dispatch(getLevelPLan())
    }).catch(error => {
      Sentry.captureException(error);
    });
}

export const GetAllWorkoutInfo = (dateKey, workoutIndex, dateKeyIndex) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { userSchedule } = state.levels;
  const date = getCalanderDate(timezone)[dateKeyIndex];
  const legacyWorkout = userSchedule[dateKey][workoutIndex];
  const courseName = legacyWorkout.category.replace('Foundation ', '');

  Axios(AxiosConfig('get', `/workout-service/edit-workout/users/${UserId}?workoutType=${courseName}`, webToken))
    .then(res => {
      dispatch({
        type: SET_PROGRESSION,
        allProgressions: res.data.body,
        loading: false,
        name: courseName,
        showEditModal: true,
        date: date
      })
    }).catch(error => {
      Sentry.captureException(error);
    });
}
