import * as actionTypes from './actionTypes'
import axios from 'axios';
import jwt from 'jsonwebtoken';
import * as Sentry from "@sentry/react";
import moment from 'moment-timezone'

// import { SetCaladner } from './calendarActions';
// import { SetAllClassesInitial } from './actionsSetAllClasses';
// import { SetAllSubClassesInitial } from './actionsSetAllSubClasses';
import LogRocket from 'logrocket';

import { showToast } from './calendarActions'
import { AxiosConfig } from '../util'

const API = process.env.REACT_APP_API;

const LoginAsync = (webToken, data, userState, timezone) => {

  if (process.env.REACT_APP_TESTING === 'true') {
    LogRocket.identify(data.cid, {
      name: `${data.fname} ${data.lname}`,
      email: data.sub,
    });
  }

  if (process.env.REACT_APP_IS_PRODUCTION === 'production') {
    Sentry.setUser({
      name: `${data.fname} ${data.lname}`,
      email: data.sub,
      userId: data.cid,
    });
  }

  return {
    type: actionTypes.LOGIN_SUCESS,
    webToken: webToken,
    userData: data,
    userState,
    timezone
  }
}

const LoginStart = () => {
  return {
    type: actionTypes.LOGN_START
  }
}
// TODO: Once back end is done this will need to be used
const StartFailLogin = () => {
  return {
    type: actionTypes.LOGIN_FAIL
  }
}

const clearLoginFail = () => {
  return {
    type: actionTypes.LOGIN_FAIL_CLEAR
  }
}

export const setNewAuthToken = (token) => {
  return {
    type: actionTypes.SET_NEW_AUTH,
    webToken: token
  }
}

export const checkWelcomeService = (userData) => {
  return {
    type: actionTypes.CHECK_WELCOME_SERVICE,
    payload: userData
  }
}

export const loginFail = () => dispatch => {
  dispatch(StartFailLogin())
  dispatch(Logout());
  dispatch(showToast('Something went wrong please try again.', 'error'))
  setTimeout(() => {
    dispatch(clearLoginFail());
  }, 2500)
}

export const fourceLogoutFail = () => dispatch => {
  dispatch(StartFailLogin())
  dispatch(Logout());
  dispatch(showToast('You have been logged out for your security.', 'success'))
  setTimeout(() => {
    dispatch(clearLoginFail());
  }, 2500)
}

const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    // console.log('Setting Auto Loggout', expirationTime)
    setTimeout(() => {
      dispatch(Logout());
      dispatch(showToast('You have been logged out for your security.', 'success'))
    }, expirationTime);
  };
};

export const Login = (username, password) => dispatch => {
  dispatch(LoginStart());

  const config = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  }

  axios.post(API + '/auth', { username, password, timezone: moment.tz.guess() }, config)
    .then(res => {
      const authToken = res.data.jwtAuthorizationToken;
      const refreshToken = res.data.jwtRefreshToken;

      const decodeRefresh = jwt.decode(refreshToken);
      const decoded = jwt.decode(authToken);

      const expirationDate = new Date(new Date().getTime() + (decoded.exp - decoded.iat) * 1000);
      const refreshExpireTime = new Date(new Date().getTime() + (decodeRefresh.exp - decodeRefresh.iat) * 1000);

      const { timezone } = res.data;

      localStorage.setItem('authToken', authToken);
      localStorage.setItem('AuthExpirationDate', expirationDate);

      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('refreshExpireTime', refreshExpireTime);

      localStorage.setItem('timezone', timezone);

      let userConfig = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": `Bearer ${authToken}`
        }
      }

      if (res.data.isFreeMember && (!res.data.isAllAccessUser && !res.data.hasCourseProduct)) {
        userConfig = {
          ...userConfig,
          method: 'GET',
          url: `${API}/welcome/v1/users`,
        }

        axios(userConfig)
          .then(res => dispatch(
            LoginAsync(
              authToken,
              decoded,
              {
                ...res.data,
                showAllAccessSite: true,
                isFreeMember: true
              },
              timezone)
          ))
          .catch(err => {
            dispatch(loginFail())
            Sentry.captureException(err);
          });
      }

      else {
        userConfig = {
          ...userConfig,
          method: 'GET',
          url: `${API}/welcome/v1/users`,
        }

        axios(userConfig)
          .then(res => dispatch(
            LoginAsync(
              authToken,
              decoded,
              {
                ...res.data,
                showAllAccessSite: true,
                isFreeMember: false
              },
              timezone)
          )).catch(err => {
            dispatch(loginFail())
            Sentry.captureException(err);
          });
      }
    })
    .catch(err => {
      dispatch(loginFail())
      Sentry.captureException(err);
    });
}

export const authCheckState = () => dispatch => {
  const authToken = localStorage.getItem('authToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const refreshExpireTime = localStorage.getItem('refreshExpireTime');
  const authExpireTime = localStorage.getItem('AuthExpirationDate');
  const timezone = localStorage.getItem('timezone');

  if (!authToken || !refreshToken || !refreshExpireTime || !authExpireTime || !timezone) {
    dispatch(setDidTryAL());
    dispatch(Logout());
  }
  else {
    // const authExpirationDate = new Date(authExpireTime);
    const refreshExpireDate = new Date(refreshExpireTime);
    const currentDate = new Date();

    // console.log('3 days from initial login is:', refreshExpireDate);
    // console.log('24 hours from now:', authExpirationDate);
    // console.log('Today is:', currentDate);
    // console.log('Your Time zone:', timezone);

    let OneDay = currentDate.getTime() + (24 * 60 * 60 * 1000)

    if (OneDay > refreshExpireDate && !(refreshExpireDate <= currentDate)) {
      dispatch(checkAuthTimeout(refreshExpireDate.getTime() - new Date().getTime()))
    }



    if (refreshExpireDate <= currentDate) {
      dispatch(setDidTryAL());
      dispatch(Logout());
    } else {
      // if (authExpirationDate <= currentDate) {
      //   console.log('hello')
      //   dispatch(getNewAuthToken(refreshToken));
      // }
      // else {

      //   let userDataConfig = {
      //     method: 'get',
      //     url: `http://gymfit-membersite-test-lb-645626039.us-east-1.elb.amazonaws.com/welcome/users`,
      //     headers: {
      //       'Authorization': `Bearer ${authToken}`,
      //     },
      //   };

      //   axios(userDataConfig).then(userData => {
      //     dispatch(SetCaladner(userData.data.usersSchedule));
      //     dispatch(SetAllSubClassesInitial(userData.data.myCourses));
      //     dispatch(SetAllClasses(userData.data.myCourses));
      //     dispatch(LoginAsync(authToken, decoded, userData.data));
      //     dispatch(setDidTryAL());
      //   }).catch(err => {
      //     dispatch(loginFail())
      //   });
      // }
      dispatch(getNewAuthToken(refreshToken));
    }
  }
};

const getNewAuthToken = (refreshToken) => dispatch => {
  let newAuthConfig = {
    method: 'get',
    url: `${API}/auth/refreshToken`,
    headers: {
      'Authorization': `Bearer ${refreshToken}`,
    },
  };

  axios(newAuthConfig).then(res => {
    const newAuthToken = res.data.authorizationToken;
    const decoded = jwt.decode(newAuthToken)


    const expirationDate = new Date(new Date().getTime() + (decoded.exp - decoded.iat) * 1000);
    const { timezone } = res.data;

    localStorage.setItem('authToken', newAuthToken);
    localStorage.setItem('AuthExpirationDate', expirationDate);
    localStorage.setItem('timezone', timezone);

    let userDataConfig = {
      method: 'get',
      url: `${API}/welcome/users`,
      headers: {
        'Authorization': `Bearer ${newAuthToken}`,
      },
    };

    if (res.data.isFreeMember && (!res.data.isAllAccessUser && !res.data.hasCourseProduct)) {
      userDataConfig = {
        ...userDataConfig,
        method: 'GET',
        url: `${API}/welcome/v1/users`,
      }

      axios(userDataConfig)
        .then(res => dispatch(
          LoginAsync(
            newAuthToken,
            decoded,
            {
              ...res.data,
              showAllAccessSite: true,
              isFreeMember: true
            },
            timezone)
        ))
        .catch(err => {
          dispatch(loginFail())
          Sentry.captureException(err);
        });
    }
    else {
      userDataConfig = {
        ...userDataConfig,
        method: 'GET',
        url: `${API}/welcome/v1/users`,
      }

      axios(userDataConfig)
        .then(res => dispatch(
          LoginAsync(
            newAuthToken,
            decoded,
            {
              ...res.data,
              showAllAccessSite: true,
              isFreeMember: false
            },
            timezone)
        ))
        .catch(err => {
          dispatch(loginFail())
          Sentry.captureException(err);
        });
    }
  }).catch(err => {
    dispatch(loginFail())
    Sentry.captureException(err);
  })
}


export const Logout = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshExpireTime');
  localStorage.removeItem('AuthExpirationDate');
  localStorage.removeItem('timezone');
  Sentry.configureScope(scope => scope.setUser(null));
  return {
    type: actionTypes.LOGOUT
  }
}

export const setDidTryAL = () => {
  return { type: actionTypes.SET_DID_TRY_AL };
};


export const getNewSignedUrl = () => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  const config = AxiosConfig('get', `/welcome/v1/users/${UserId}/refresh/player/signed-url`, webToken)

  axios(config).then(res => {
    dispatch({
      type: actionTypes.GET_NEW_SIGNED_URL,
      payload: {
        signedUrl: res.data.playerScript
      }
    })
  }).catch(err => {
    Sentry.captureException(err);
  })
}

export const UpdateUserLevelId = (levelData) => {
  return {
    type: actionTypes.UPDATED_USER_LEVEL,
    payload: {
      ...levelData
    }
  }
}
