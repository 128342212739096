export const levels = [
  {
    image: '600X600-level-intro-bundle.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/IntroPlan-Intro.pdf',
  },
  {
    image: '600X600-level1-bundle.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/2021-Level1.pdf',
  },
  {
    image: '600X600-level2-bundle.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/2021-Level2.pdf',
  },
  {
    image: '600X600-level3-bundle.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/2021-Level3.pdf',
  },
  {
    image: '600X600-level4-bundle.jpg',
    pdf: 'https://www.gymnasticbodies.com/media/2021-Level4.pdf',
  },
]
