import React from 'react';


export const Books = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 87.71 85.097">
      <g id="Group_4864" data-name="Group 4864" transform="translate(-306.015 -429.15)">
        <path id="Path_4666" data-name="Path 4666" d="M393.63,472.586c-1.6,5.127-1.152,10.274.1,15.79l-42.171,25.871-18.707-8.006q-10.254-4.387-20.506-8.78c-4.8-2.061-6.931-5.668-6.178-10.86.309-2.127,1.018-4.2,1.62-6.588-2.543-4.518-2.224-9.36.213-14.107a14.049,14.049,0,0,1,.475-15.094,8.582,8.582,0,0,1,3.151-2.992c12.211-6.27,24.481-12.426,36.843-18.67q16.164,4.91,32.419,9.847c3.392,1.031,6.79,2.047,10.164,3.134,2.646.852,2.739,1.372,2.166,3.943a21.527,21.527,0,0,0,.372,11.84C392.323,462,392.25,469.507,393.63,472.586Zm-42.442,7.687,39.038-23.931v-8.449l-38.741,22.3-39.37-17.115a34.843,34.843,0,0,0-1.393,3.387c-1.138,3.959.119,6.544,3.914,8.184,3.15,1.361,6.308,2.7,9.463,4.049Zm-.016,29.041,39.06-23.949v-9.259c-1.452.848-2.522,1.448-3.566,2.088-10.635,6.515-21.3,12.977-31.867,19.6a6.417,6.417,0,0,1-6.7.525c-10.882-4.792-21.833-9.428-32.764-14.107-1.117-.478-2.272-.867-3.368-1.281-2.981,5.677-1.959,8.745,3.447,11.058q9.617,4.116,19.241,8.217C340.116,504.547,345.565,506.9,351.172,509.314Zm39.073-47.606c-1.332.727-2.326,1.217-3.268,1.793-10.732,6.57-21.495,13.093-32.153,19.781a6.4,6.4,0,0,1-6.691.556C337.15,479,326.1,474.32,315.062,469.6c-1.015-.434-2.075-.764-3.071-1.127-3.03,5.473-1.981,8.646,3.406,10.963q16.381,7.045,32.761,14.1c1.032.443,2.1.795,3.156,1.19l38.931-23.887Zm-37.382-25.934-13.031,7,18.647,8.148c4.047-2.739,8.162-5.005,12.45-8.176Z" fill="#184168" />
        <path id="Path_4667" data-name="Path 4667" d="M351.188,480.273,324.1,468.7c-3.155-1.348-6.313-2.688-9.463-4.049-3.795-1.64-5.052-4.225-3.914-8.184a34.843,34.843,0,0,1,1.393-3.387l39.37,17.115,38.741-22.3v8.449Z" fill="#fff" />
        <path id="Path_4668" data-name="Path 4668" d="M351.172,509.314c-5.607-2.413-11.056-4.767-16.513-7.1q-9.617-4.119-19.241-8.217c-5.406-2.313-6.428-5.381-3.447-11.058,1.1.414,2.251.8,3.368,1.281,10.931,4.679,21.882,9.315,32.764,14.107a6.417,6.417,0,0,0,6.7-.525c10.565-6.627,21.232-13.089,31.867-19.6,1.044-.64,2.114-1.24,3.566-2.088v9.259Z" fill="#fff" />
        <path id="Path_4669" data-name="Path 4669" d="M390.245,461.708v9.127l-38.931,23.887c-1.054-.395-2.124-.747-3.156-1.19q-16.386-7.038-32.761-14.1c-5.387-2.317-6.436-5.49-3.406-10.963,1,.363,2.056.693,3.071,1.127,11.034,4.723,22.088,9.4,33.071,14.241a6.4,6.4,0,0,0,6.691-.556c10.658-6.688,21.421-13.211,32.153-19.781C387.919,462.925,388.913,462.435,390.245,461.708Z" fill="#fff" />
        <path id="Path_4670" data-name="Path 4670" d="M352.863,435.774l18.066,6.967c-4.288,3.171-8.4,5.437-12.45,8.176l-18.647-8.148Zm5.4,12.808,8.24-5.339-13.544-5.194-8.356,4.542Z" fill="#fff" />
        <path id="Path_4671" data-name="Path 4671" d="M358.262,448.582l-13.66-5.991,8.356-4.542,13.544,5.194Z" fill="#184168" />
      </g>
    </svg>
  )
}

export const Bones = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 95.422 95.509">
      <g id="Group_4856" data-name="Group 4856" transform="translate(-318.561 -409.244)">
        <circle id="Ellipse_4" data-name="Ellipse 4" cx="47.589" cy="47.589" r="47.589" transform="translate(318.608 409.575)" fill="#184168" />
        <path id="Path_4646" data-name="Path 4646" d="M455.926,503.638a11.589,11.589,0,0,1,11.282,11.532c-.012,6.162-5.483,11.679-11.563,11.468-5.935-.206-11.543-5.017-11.344-11.7A11.481,11.481,0,0,1,455.926,503.638Z" transform="translate(-84.105 -63.141)" fill="#fff" />
        <path id="Path_4647" data-name="Path 4647" d="M341.787,536.963c1.295-2.364,1.3-4.114-.5-5.724-4.063-3.631-14.907-12.886-20.868-17.854a47.773,47.773,0,0,0,12.41,47.248c1.194-3.335,2.736-7.678,3.508-10.147C337.054,548.193,340.612,539.108,341.787,536.963Z" transform="translate(0 -69.66)" fill="#ccc" />
        <path id="Path_4648" data-name="Path 4648" d="M511.564,456.948a47.709,47.709,0,0,0-47.123-47.7c6.427,5.923,20.45,18.8,25.394,24.863,2.021,2.1,4.147,4.394,4.829,6.3a34.992,34.992,0,0,1,1.792,5.916c.647,3.029.122,7.451-.575,11.127-.308,1.624-1.171,3.219-1.147,4.817.088,5.688.4,11.374.725,17.055.172,2.972.379,8.677.615,12.807A47.583,47.583,0,0,0,511.564,456.948Z" transform="translate(-97.58 0)" fill="#ccc" />
        <path id="Path_4649" data-name="Path 4649" d="M357.179,434.646c3.276,2.823,8.028,7.225,8.732,9.575,1.088,3.635-.777,8,2.708,12.116,2.554,3.016,7.058,4.89,11.512,2.322,4.221-2.434,3.955-4.141,6.011-6.535,2.44-2.842,9.125-4.441,8.844-10.417s-1.981-8.734-7.835-9.924-7.635.643-9.99-1.81c-.547-.4-3.871-3.261-6.446-5.484A47.858,47.858,0,0,0,357.179,434.646Z" transform="translate(-25.832 -10.198)" fill="#e6e6e6" />
        <path id="Path_4650" data-name="Path 4650" d="M456.485,621.866c-.216-4.07-.634-10.012-.381-11.779a5.091,5.091,0,0,1,2.3-4.048c2.625-1.969,5.619-5.056,5.5-8.373a12.1,12.1,0,0,0-2.045-5.719c-2.485-3.88-7.036-4.868-11.2-3.68a11.78,11.78,0,0,1-5.561.032c-2.391-.529-4.643-.949-6.952.144a9.935,9.935,0,0,0-5.765,6.689,9.055,9.055,0,0,0,1.133,8c2.12,2.91,5.3,3.753,6.219,7.417.5,2,.43,5.627.731,7.669a.174.174,0,0,1-.018.083c.058.262.009,3.63-.029,5.736.565.02,1.132.034,1.7.034A47.683,47.683,0,0,0,456.485,621.866Zm-1.635-3.97h0Zm.546,3.882-.6.2Zm-2.166.2-3.749.978Z" transform="translate(-75.844 -119.411)" fill="#e6e6e6" />
        <path id="Path_4651" data-name="Path 4651" d="M501.125,678.779h0Z" transform="translate(-122.118 -180.294)" fill="#e6e6e6" />
      </g>
    </svg>
  )
}

export const Man = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 81.729 81.73">
      <g id="Group_4894" data-name="Group 4894" transform="translate(-190.418 -1479.214)">
        <rect id="Rectangle_1091" data-name="Rectangle 1091" width="73" height="73" transform="translate(196 1486)" fill="#184168" />
        <g id="Group_4893" data-name="Group 4893" transform="translate(190.418 1479.214)">
          <g id="Group_4892" data-name="Group 4892" transform="translate(11.374 9.351)">
            <path id="Path_4673" data-name="Path 4673" d="M874.87,356.172a6.279,6.279,0,1,1,6.279,6.278A6.279,6.279,0,0,1,874.87,356.172Z" transform="translate(-851.221 -349.893)" fill="#fff" />
            <path id="Path_4674" data-name="Path 4674" d="M898.576,367.857l21.557-9.3-1.556-3.886-26.279,9h-4.186l-26.279-9-1.554,3.886,21.555,9.3V384.6l-8.585,27.769,3.914,1.483,12.147-27.158H891.1L903.25,413.85l3.912-1.483L898.578,384.6Z" transform="translate(-860.278 -346.927)" fill="#fff" />
          </g>
          <path id="Path_4675" data-name="Path 4675" d="M894.124,425.853a40.865,40.865,0,1,1,40.865-40.865A40.911,40.911,0,0,1,894.124,425.853Zm0-78.489a37.624,37.624,0,1,0,37.624,37.624A37.666,37.666,0,0,0,894.124,347.364Z" transform="translate(-853.26 -344.123)" fill="#fff" />
          <path id="Path_4676" data-name="Path 4676" d="M928.937,421H856.35V348.409h72.587Zm-69.345-3.241h66.1v-66.1h-66.1Z" transform="translate(-851.342 -341.463)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}


export const Handstand = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 107.186 64.367">
      <g id="Group_4863" data-name="Group 4863" transform="translate(-194.737 -330.483)">
        <rect id="Rectangle_1089" data-name="Rectangle 1089" width="105.651" height="61.668" transform="translate(195.737 331.82)" fill="#184168" />
        <g id="Group_4862" data-name="Group 4862">
          <path id="Path_4659" data-name="Path 4659" d="M250.577,367.126a4.249,4.249,0,1,0,4.382,4.11A4.248,4.248,0,0,0,250.577,367.126Z" fill="#fff" />
          <path id="Path_4660" data-name="Path 4660" d="M267.1,345.353c-.986-1.456-1.993-1.016-3.349-.173-2.766,1.721-9.365,6.086-12.431,7.937l-2.613,1.391-3.184-1.663c-2.808-1.661-9.208-5.822-12.043-7.426-1.39-.786-2.414-1.183-3.339.313-.854,1.381.164,2.343,1.225,3.12,2.31,1.689,5.078,3.487,7.372,5.2,1.937,1.242,3.974,3.084,5.515,4.234-.2,1.333-1.237,7.75-1.338,9.705s-.616,12.146-.5,14.793c.062,1.435.166,2.577,1.6,2.634,1.615.065,1.808-1.532,1.773-2.639-.1-3.208.173-8.229.288-10.127s.978-2.072,1.247.079a76.842,76.842,0,0,1,.479,9.99c-.023,1.424-.1,2.855,1.446,2.929,1.5.071,1.87-1.154,1.984-2.45.193-2.187-.352-13.9-.352-13.9.317-1.51.968-7.573,1.422-10.488l0-.005c2.241-1.607,4.245-3.163,6.55-4.781,2.221-1.8,4.911-3.717,7.148-5.5C267.038,347.7,268.013,346.7,267.1,345.353Z" fill="#fff" />
          <path id="Path_4661" data-name="Path 4661" d="M299.472,394.85H197.187a2.454,2.454,0,0,1-2.45-2.451V332.934a2.454,2.454,0,0,1,2.45-2.451H299.472a2.454,2.454,0,0,1,2.451,2.451V392.4A2.454,2.454,0,0,1,299.472,394.85ZM197.187,332.483a.452.452,0,0,0-.45.451V392.4a.452.452,0,0,0,.45.451H299.472a.452.452,0,0,0,.451-.451V332.934a.452.452,0,0,0-.451-.451Z" fill="#fff" />
          <g id="Group_4860" data-name="Group 4860">
            <path id="Path_4662" data-name="Path 4662" d="M201.159,346.713h-2V334.078h17.522v2H201.159Z" fill="#fff" />
            <path id="Path_4663" data-name="Path 4663" d="M298.2,391H280.677v-2H296.2V378.362h2Z" fill="#fff" />
          </g>
          <g id="Group_4861" data-name="Group 4861">
            <path id="Path_4664" data-name="Path 4664" d="M298.2,346.713h-2V336.078H280.677v-2H298.2Z" fill="#fff" />
            <path id="Path_4665" data-name="Path 4665" d="M216.682,391H199.159V378.362h2V389h15.522Z" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const Calander = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 79.397 84.691">
      <g id="Group_5330" data-name="Group 5330" transform="translate(-184.737 -1988.634)">
        <rect id="Rectangle_1090" data-name="Rectangle 1090" width="68" height="58" transform="translate(190 2008.826)" fill="#184168" />
        <path id="Path_4627" data-name="Path 4627" d="M195.06,278.905h10.586v10.586H195.06Zm15.878,0h10.586v10.586H210.937Zm15.88,0H237.4v10.586H226.818Zm-47.639,31.758h10.586v10.589H179.179Zm15.88,0h10.586v10.589H195.06Zm15.878,0h10.586v10.589H210.937ZM195.06,294.786h10.586v10.586H195.06Zm15.878,0h10.586v10.586H210.937Zm15.88,0H237.4v10.586H226.818Zm-47.639,0h10.586v10.586H179.179ZM237.4,247.147v5.292H226.818v-5.292H189.765v5.292H179.179v-5.292H168.593v84.691h79.4V247.147Zm5.294,79.4H173.885V268.319H242.7Z" transform="translate(16.144 1741.487)" fill="#fff" />
      </g>
    </svg>

  )
}

export const Pencile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 73.83 73.83">
      <path id="pencil" d="M62.294,0a11.536,11.536,0,0,1,9.229,18.458l-4.614,4.614L50.758,6.922l4.614-4.614A11.481,11.481,0,0,1,62.294,0ZM4.614,53.065,0,73.83l20.765-4.614L63.448,26.533,47.3,10.382ZM51.593,26.214l-32.3,32.3-3.978-3.978,32.3-32.3Z" fill="#fff" opacity="0.29" />
    </svg>
  )
}

export const AutoPilot = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="70" height="40" viewBox="0 0 186.968 83.862">
      <defs>
        <linearGradient id="linear-gradient" x1="0.399" y1="0.052" x2="0.672" y2="1.082" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#1e88e5" />
          <stop offset="1" stop-color="#0f4473" />
        </linearGradient>
        <linearGradient id="linear-gradient-2" x1="0.458" y1="0.069" x2="0.631" y2="1.251" xlink="#linear-gradient" />
      </defs>
      <g id="Group_5840" data-name="Group 5840" transform="translate(-119.153 -277.138)">
        <path id="Path_4983" data-name="Path 4983" d="M280.773,342.432H277.4a58.925,58.925,0,1,0-117.85,0h-3.369a62.294,62.294,0,1,1,124.588,0Z" transform="translate(-5.842 -3)" fill="url(#linear-gradient)" />
        <path id="Path_4984" data-name="Path 4984" d="M269.762,344.97h-3.369a45.377,45.377,0,1,0-90.753,0h-3.369a48.746,48.746,0,1,1,97.491,0Z" transform="translate(-8.379 -5.538)" fill="url(#linear-gradient-2)" />
        <path id="Path_4985" data-name="Path 4985" d="M293.6,354.866h-1.685v-4.211H293.6Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H285.18v-4.211h1.685Zm-3.369,0h-1.685v-4.211H283.5Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H271.7v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H261.6v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H251.49v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211H239.7Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H217.8v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211H195.9Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0H184.11v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0H174v-4.211h1.685Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0H163.9v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0H150.42v-4.211H152.1Zm-3.369,0h-1.684v-4.211h1.684Zm-3.369,0h-1.685v-4.211h1.685Zm-3.369,0h-1.684v-4.211H142Zm-3.369,0h-1.187v-4.211h1.187Z" transform="translate(-2.885 -11.124)" fill="#0f4473" />
        <path id="Path_4986" data-name="Path 4986" d="M304.6,313.076l-75.761,26.91a14.852,14.852,0,0,0-1.984-4.367l20.7-12.412c.2-.139.387-.376.206-.974-.186-.617-.454-.8-.674-.679l-23.818,10.323a14.853,14.853,0,0,0-10.458-2.779l-8.669-27.14a.566.566,0,0,0-.7-.406c-.483.052-.543.64-.485.879l6.089,27.6a14.85,14.85,0,0,0-8.148,7.794l-25.8,2.881c-.248.008-.392.3-.247.925.141.608.417.724.664.743l24.129.483a14.863,14.863,0,0,0,.465,4.841l0,.006-80.021,14.469a1.026,1.026,0,0,0-.907,1.339c.155.785.8.855,1.407.8l61.778-6.893a6.832,6.832,0,1,0,11.3-1.261l10.884-1.215a14.87,14.87,0,0,0,24.1-6.573l9.427-4.087a6.83,6.83,0,1,0,10.374-4.5l56.77-24.611c.551-.254,1.078-.637.823-1.395A1.027,1.027,0,0,0,304.6,313.076Z" transform="translate(0 -4.377)" fill="#fff" />
      </g>
    </svg>
  )
}


export const JeffersonCurl = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 80.83 91.077">
      <g id="Group_5841" data-name="Group 5841" transform="translate(497.917 -75.673)">
        <ellipse id="Ellipse_50" data-name="Ellipse 50" cx="40.415" cy="40.859" rx="40.415" ry="40.859" transform="translate(-497.917 75.673)" fill="#184168" />
        <path id="Path_4987" data-name="Path 4987" d="M-467.408,100.436c-.876,12.27-1.706,24.545-2.67,36.809-.186,2.364.16,5.408-3.565,5.617-2.73.152-4.519-.733-4.716-3.915-.938-15.074-2.044-30.136-2.965-45.208-.4-6.6,3.755-10.458,10.213-9.062A23.9,23.9,0,0,1-456.46,95.13c4.632,7.15,6.716,13.076,5.5,18.647-1.284,2.662-1.679,5.094-3.565,7.128-2.852,5.572-3.442,12.824-3.843,19.083a6.847,6.847,0,0,1-.683,3.231c-.669,1-2.094,2.432-2.839,2.247a4.252,4.252,0,0,1-2.964-4.653c.58-7.749.487-12.956.895-22.779C-463.932,113.742-465.5,102.458-467.408,100.436Z" transform="translate(13.035 12.57)" fill="#fff" />
        <path id="Path_4988" data-name="Path 4988" d="M-452.085,113.347a7.5,7.5,0,0,1-8.064,7.645,7.83,7.83,0,0,1-7.666-7.775,7.925,7.925,0,0,1,8-7.917A7.657,7.657,0,0,1-452.085,113.347Z" transform="translate(21.745 21.238)" fill="#fff" />
        <circle id="Ellipse_51" data-name="Ellipse 51" cx="7.33" cy="7.33" r="7.33" transform="translate(-455.604 152.089)" fill="#fff" />
        <rect id="Rectangle_1536" data-name="Rectangle 1536" width="17.21" height="2.178" transform="translate(-476.296 155.212)" fill="#fff" />
      </g>
    </svg>
  )
}

export const Quiz = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 85.22 78.083">
      <g id="Group_5582" data-name="Group 5582" transform="translate(-200.205 -96.171)">
        <path id="Path_4954" data-name="Path 4954" d="M281.864,134.9c0-26.585-19.759-37.357-35.451-38.578a43.314,43.314,0,0,0-4.924-.126,43.324,43.324,0,0,0-4.925.126c-15.692,1.221-36.359,11.993-36.359,38.578a2.98,2.98,0,0,0,.024.315,3.014,3.014,0,0,0-.024.316c0,26.585,20.666,37.357,36.359,38.578a43.326,43.326,0,0,0,4.925.126,43.316,43.316,0,0,0,4.924-.126c15.692-1.221,35.451-11.993,35.451-38.578a2.814,2.814,0,0,0-.024-.316A2.783,2.783,0,0,0,281.864,134.9Zm-22.205.631c0,18.252-1.181,34.644-18.17,34.644s-19.095-16.392-19.095-34.644c0-.109-.013-.212-.021-.316.008-.1.021-.206.021-.315,0-18.252,4.358-34.644,19.095-34.644s18.17,16.392,18.17,34.644c0,.109.013.212.021.315C259.671,135.316,259.659,135.418,259.659,135.527Z" transform="translate(0)" fill="#184168" />
        <path id="Path_4955" data-name="Path 4955" d="M277.115,134.984c0-25.592-16.685-35.96-31.277-37.135a38.908,38.908,0,0,0-4.579-.122,38.9,38.9,0,0,0-4.579.122C222.09,99.024,205.4,109.392,205.4,134.984a2.767,2.767,0,0,0,.023.3,2.8,2.8,0,0,0-.023.3c0,25.592,16.686,35.96,31.277,37.135a38.918,38.918,0,0,0,4.579.122,38.921,38.921,0,0,0,4.579-.122c14.591-1.175,31.277-11.543,31.277-37.135a2.819,2.819,0,0,0-.022-.3A2.783,2.783,0,0,0,277.115,134.984Zm-11.212.606c0,17.569-5.626,36.664-24.644,35.974s-24.645-18.4-24.645-35.974c0-.1-.012-.2-.019-.3s.019-.2.019-.3c0-17.57,7.974-35.974,24.645-35.974s24.644,18.4,24.644,35.974c0,.1.012.2.02.3C265.915,135.388,265.9,135.487,265.9,135.591Z" transform="translate(-0.256 -0.076)" fill="#fff" />
        <path id="Path_4956" data-name="Path 4956" d="M238,174.583c4.183-3.608,14.931-3.6,24.772,2.536,17.843,11.127,29.1-7.259,28.49-8.583-11.582,8.733-18.187,4.89-27.972-.144C248.951,161.017,237.415,174.252,238,174.583Z" transform="translate(-5.861 -7.116)" fill="#184168" />
        <path id="Path_4957" data-name="Path 4957" d="M238,174.539c4.183-3.608,16.117-6.736,25.913-.53,16.94,10.733,27.887-4.225,27.35-5.516-11.582,8.733-17.236,3.939-27.021-1.1C249.9,160.022,237.415,174.208,238,174.539Z" transform="translate(-5.861 -7.072)" fill="#fff" />
      </g>
    </svg>
  )
}

export const Tools = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 104.029 104.029">
      <g id="Group_5314" data-name="Group 5314" transform="translate(-386.893 -596.648)">
        <circle id="Ellipse_30" data-name="Ellipse 30" cx="52.015" cy="52.015" r="52.015" transform="translate(386.893 596.648)" fill="#184168" />
        <g id="Group_5313" data-name="Group 5313">
          <path id="Path_4896" data-name="Path 4896" d="M450.16,615.393a26.038,26.038,0,0,1,11.931,2.906,26.533,26.533,0,0,1,5.2,3.721,5.544,5.544,0,0,0,.577.486,1.148,1.148,0,0,1,.451,1.356,6.24,6.24,0,0,0,.173,3.676,5.115,5.115,0,0,0,6.356,2.926,1.07,1.07,0,0,1,1.17.26q.881.815,1.731,1.663,1.98,1.971,3.946,3.958c.16.161.276.367.437.527a.521.521,0,0,1-.01.826c-1.14,1.218-2.26,2.455-3.428,3.646q-2.918,2.975-5.89,5.9c-1.026,1.009-2.109,1.959-3.161,2.941a.544.544,0,0,1-.854-.023c-.776-.743-1.562-1.475-2.333-2.223q-1.587-1.541-3.154-3.1a2.329,2.329,0,0,1-.816-1.354,3.526,3.526,0,0,1,.051-1.014A5.613,5.613,0,0,0,459.6,636.4a3.626,3.626,0,0,0-2.122-.4,2.779,2.779,0,0,0-1.647.832q-5.947,5.961-11.9,11.915c-1.424,1.422-2.832,2.863-4.314,4.221a5.656,5.656,0,0,0-1.969,4.076,5.053,5.053,0,0,1-1.847,3.529c-1.944,1.886-3.841,3.821-5.756,5.737-1.5,1.5-3.013,3-4.508,4.515-2.556,2.585-5.091,5.193-7.661,7.765-1.057,1.058-2.169,2.063-3.285,3.06-.5.447-.568.448-1.077,0-1.174-1.035-2.357-2.066-3.457-3.177-1.681-1.7-3.314-3.441-4.929-5.2-.651-.71-.556-.564.02-1.175,1.794-1.906,3.6-3.8,5.452-5.654q6.888-6.914,13.818-13.786c.81-.807,1.623-1.611,2.454-2.4a4.346,4.346,0,0,1,3.239-1.324,3.976,3.976,0,0,0,2.96-1.3q2.214-2.151,4.4-4.335c2.714-2.7,5.432-5.4,8.126-8.121,1.214-1.226,2.371-2.509,3.563-3.757.249-.262.274-.433,0-.726a8.692,8.692,0,0,0-9.632-2.671c-.687.21-1.364.455-2.041.7a.552.552,0,0,1-.626-.11c-.582-.548-1.178-1.082-1.755-1.634-.636-.608-1.266-1.223-1.882-1.851a9.14,9.14,0,0,1-1.015-1.151,1.025,1.025,0,0,1,.159-1.484,25.228,25.228,0,0,1,2.129-1.777,25.8,25.8,0,0,1,7.006-3.835A27.029,27.029,0,0,1,450.16,615.393Z" fill="#fff" />
          <path id="Path_4897" data-name="Path 4897" d="M436.072,640.751c-.408.463-.8.938-1.227,1.385-.9.954-1.853,1.868-2.73,2.847a4.457,4.457,0,0,1-2.994,1.473c-.543.058-1.077.193-1.616.291a4.172,4.172,0,0,0-1.971,1.107c-.616.531-.48.517-1.041-.022-1.119-1.077-2.18-2.214-3.267-3.324-.328-.335-.656-.67-1-.991a3.433,3.433,0,0,0-2.564-.931c-2.1.053-4.206.061-6.308.079a4.9,4.9,0,0,1-1.092-.07,1.872,1.872,0,0,1-.882-.395c-2.236-2.176-4.446-4.379-6.669-6.57a4.125,4.125,0,0,1-1.222-3.128c.015-1.555-.012-3.11,0-4.666.006-.993.044-1.986.073-2.979a2.49,2.49,0,0,1,.06-.371c.107-.551.24-.652.723-.348a9.838,9.838,0,0,1,1.646,1.272c1.788,1.751,3.545,3.534,5.3,5.315.407.411.777.859,1.2,1.332a97.32,97.32,0,0,0,7.581-7.592c-.417-.419-.785-.792-1.156-1.161q-2.55-2.541-5.1-5.079c-.332-.329-.682-.641-1.017-.969a5.073,5.073,0,0,1-.608-.646.669.669,0,0,1,.427-1.123,5.452,5.452,0,0,1,1.084-.143q1.971-.033,3.941-.019c1.36.012,2.721.059,4.081.08a2.036,2.036,0,0,1,1.465.631q3.009,2.98,6.037,5.941a4.224,4.224,0,0,1,1.574,2.793c.035.707.037,1.417.028,2.125-.022,1.692-.035,3.384-.1,5.075a2.543,2.543,0,0,0,.852,2.071c1.328,1.257,2.647,2.525,3.943,3.815.809.8,1.573,1.656,2.349,2.494a3.428,3.428,0,0,1,.315.48Z" fill="#fff" />
          <path id="Path_4898" data-name="Path 4898" d="M468.108,675.535a6.576,6.576,0,0,1-4.831,6.175,6.176,6.176,0,0,1-5.178-.629,14.07,14.07,0,0,1-2.169-1.833c-2.118-2.035-4.228-4.081-6.309-6.155-2.81-2.8-5.593-5.628-8.383-8.448q-1.374-1.389-2.716-2.806a.352.352,0,0,1,.012-.327c.3-.433.642-.834.921-1.277a5.247,5.247,0,0,0,.741-2.505,4.031,4.031,0,0,1,1.213-2.816c1.435-1.376,2.844-2.78,4.253-4.182.219-.218.371-.179.567.008,1.142,1.088,2.309,2.151,3.43,3.26,2.121,2.1,4.219,4.222,6.32,6.341,1.239,1.251,2.456,2.523,3.7,3.771,1.984,1.993,4,3.953,5.964,5.967a8.208,8.208,0,0,1,2.4,4.6C468.084,674.963,468.087,675.25,468.108,675.535Z" fill="#fff" />
        </g>
      </g>
    </svg>

  )
}


export const Focus = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 87 87">
      <g id="Group_5805" data-name="Group 5805" transform="translate(-179 -1289.059)">
        <circle id="Ellipse_36" data-name="Ellipse 36" cx="43.5" cy="43.5" r="43.5" transform="translate(179 1289.059)" fill="#184168" />
        <g id="Group_5492" data-name="Group 5492" transform="translate(183.502 1293.561)">
          <g id="Ellipse_37" data-name="Ellipse 37" transform="translate(9.498 9.498)" fill="none" stroke="#fff" strokeWidth="5">
            <circle cx="29.5" cy="29.5" r="29.5" stroke="none" />
            <circle cx="29.5" cy="29.5" r="27" fill="none" />
          </g>
          <g id="Ellipse_38" data-name="Ellipse 38" transform="translate(21.498 20.498)" fill="none" stroke="#fff" strokeWidth="5">
            <circle cx="18" cy="18" r="18" stroke="none" />
            <circle cx="18" cy="18" r="15.5" fill="none" />
          </g>
          <g id="Group_5491" data-name="Group 5491" transform="translate(39.395 0.001)">
            <line id="Line_152" data-name="Line 152" y2="25.215" fill="none" stroke="#fff" strokeWidth="5" />
            <line id="Line_153" data-name="Line 153" y2="25.215" transform="translate(0 53.238)" fill="none" stroke="#fff" strokeWidth="5" />
          </g>
          <g id="Group_5490" data-name="Group 5490" transform="translate(78.455 38.27) rotate(90)">
            <line id="Line_154" data-name="Line 154" y2="25.215" fill="none" stroke="#fff" strokeWidth="5" />
            <line id="Line_155" data-name="Line 155" y2="25.215" transform="translate(0 53.24)" fill="none" stroke="#fff" strokeWidth="5" />
          </g>
        </g>
      </g>
    </svg>


  )
}

export const Thrive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 90.106 95.462">
      <g id="Group_5358" data-name="Group 5358" transform="translate(-269.306 -519.49)">
        <circle id="Ellipse_33" data-name="Ellipse 33" cx="45.053" cy="45.053" r="45.053" transform="translate(269.306 519.49)" fill="#184168" />
        <g id="Group_5356" data-name="Group 5356" transform="translate(313.765 519.491)">
          <path id="Path_4923" data-name="Path 4923" d="M310.252,523.153a1.464,1.464,0,0,0-1.46,1.46v3.337a1.623,1.623,0,0,0-.584,1.247v.957a9.706,9.706,0,0,1,3.428-.487v-.013h4.534v.013a9.712,9.712,0,0,1,3.428.487V529.2a1.625,1.625,0,0,0-.585-1.247v-3.337a1.464,1.464,0,0,0-1.46-1.46Z" transform="translate(-297.274 -523.153)" fill="#c7c9cb" fillRule="evenodd" />
          <path id="Path_4924" data-name="Path 4924" d="M317.158,530.021h-2.266a5.323,5.323,0,0,0-4.118,1.391c-4,3.449-9.474,9.58-9.025,16.139q.141,2.083.273,4.107a2.084,2.084,0,0,0-1.492,1.992v3.865a2.081,2.081,0,0,0,1.98,2.073l.067,1.195a2.085,2.085,0,0,0-1.719,2.045v3.865a2.082,2.082,0,0,0,2.076,2.076h.03l.047,1.176a2.084,2.084,0,0,0-1.824,2.061v3.865a2.081,2.081,0,0,0,2.066,2.076l.023,1.185a2.086,2.086,0,0,0-1.762,2.052v3.866a2.081,2.081,0,0,0,1.826,2.059l0,1.193a2.084,2.084,0,0,0-1.821,2.061v3.865a2.081,2.081,0,0,0,1.692,2.039l-.034,1.2a2.081,2.081,0,0,0-1.985,2.074v3.866a2.084,2.084,0,0,0,1.666,2.035l-.077,1.511a4.758,4.758,0,0,0,4.821,4.819h19.124a4.758,4.758,0,0,0,4.821-4.819l-.077-1.515a2.086,2.086,0,0,0,1.646-2.031v-3.866a2.083,2.083,0,0,0-1.965-2.072l-.033-1.2a2.086,2.086,0,0,0,1.67-2.037v-3.865a2.084,2.084,0,0,0-1.8-2.058l0-1.2a2.084,2.084,0,0,0,1.8-2.058v-3.866a2.084,2.084,0,0,0-1.742-2.048l.023-1.189a2.083,2.083,0,0,0,2.046-2.076v-3.865a2.084,2.084,0,0,0-1.8-2.058l.047-1.179h.007a2.082,2.082,0,0,0,2.076-2.076v-3.865a2.084,2.084,0,0,0-1.7-2.041l.067-1.2a2.083,2.083,0,0,0,1.958-2.072v-3.865a2.087,2.087,0,0,0-1.471-1.987q.13-2.027.275-4.113c.45-6.559-5.027-12.69-9.025-16.139a5.325,5.325,0,0,0-4.118-1.391Z" transform="translate(-300.53 -520.241)" fill="#c7c9cb" fillRule="evenodd" />
          <path id="Path_4925" data-name="Path 4925" d="M307.233,561.691a2.179,2.179,0,0,0-2.431,2.445v26.284a3.791,3.791,0,0,0,3.771,3.77H322.1a3.792,3.792,0,0,0,3.771-3.77V564.136a2.18,2.18,0,0,0-2.432-2.445Z" transform="translate(-298.718 -506.809)" fill="#184168" fillRule="evenodd" />
        </g>
        <g id="Group_5357" data-name="Group 5357" transform="translate(281.805 566.226)">
          <path id="Path_4926" data-name="Path 4926" d="M292.935,563.951c.045.47.309.658.787.6,5.679-.671,4.883-6.574,4.347-9.515a.39.39,0,0,0-.663-.272,10.475,10.475,0,0,0-4.472,9.186Z" transform="translate(-270.045 -554.66)" fill="#fff" fillRule="evenodd" />
          <path id="Path_4927" data-name="Path 4927" d="M291.422,599.577a15.944,15.944,0,0,1,10.285,0c6.283,1.735,11-6.659,13.172-11.541,4.306-9.69,1.593-20.63-5.41-23.092-3.807-1.34-6.211.1-9.085,1.143a10.069,10.069,0,0,1-7.622.008c-2.882-1.049-5.288-2.495-9.105-1.151-7,2.462-9.717,13.4-5.411,23.092,2.171,4.882,6.892,13.276,13.175,11.541Z" transform="translate(-276.18 -551.083)" fill="#fff" fillRule="evenodd" />
          <path id="Path_4928" data-name="Path 4928" d="M294.19,567.977c-.245-4.874-2.958-10.99-7.691-12.908-1.405-.569-2.532,2.368-1.118,2.939,4.547,1.844,6.587,5.1,6.838,9.906a6.928,6.928,0,0,0,1.215.108A6.471,6.471,0,0,0,294.19,567.977Z" transform="translate(-273.041 -554.536)" fill="#fff" fillRule="evenodd" />
        </g>
      </g>
    </svg>
  )
}

export const Map = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 77.002 82.835">
      <path id="Path_4992" data-name="Path 4992" d="M-19653-11495.376v67.06l20.42,11.021,19.309-11.021,15.158,11.021,20.041-11.021v-67.06l-20.041,8.693-15.158-8.693-19.309,8.693Z" transform="translate(19653 11499.188)" fill="#184168" />
      <g id="Group_6019" data-name="Group 6019">
        <path id="Path_373" data-name="Path 373" d="M127.387,208.233,111.8,198.5,92.826,208.865,72.81,197.481v71.478l20.228,11.357L111.7,269.992l15.951,9.661,22.161-10.613V197.748ZM93.98,212.362l16.184-8.842V266.7L93.98,275.655Zm19.806-8.355,12.358,7.722V274.5l-12.358-7.485Zm-37.353-.3,13.925,7.919v63.031l-13.925-7.819Zm69.757,63.051-16.423,7.864v-63.5l16.423-7.679Z" transform="translate(-72.81 -197.481)" fill="#fff" />
        <g id="Group_252" data-name="Group 252" transform="translate(5.782 25.274)">
          <path id="Path_374" data-name="Path 374" d="M81.3,266.809l-2.106-1.719c.245-.3.489-.634.738-1l2.242,1.532C81.864,266.082,81.577,266.469,81.3,266.809Z" transform="translate(-79.195 -229.042)" fill="#fff" />
          <path id="Path_375" data-name="Path 375" d="M83.925,259.526l-2.342-1.377c.254-.433.513-.88.776-1.336l.624-1.076,2.347,1.369-.619,1.066C84.445,258.635,84.183,259.087,83.925,259.526Zm2.822-4.77-2.3-1.451c.571-.9,1.1-1.686,1.625-2.392l2.184,1.616C87.777,253.18,87.282,253.908,86.747,254.756Zm20.007-3.484a9.73,9.73,0,0,1-3.115-.781l1.046-2.507a7.041,7.041,0,0,0,2.266.578Zm3.189-.305-.654-2.637a14.1,14.1,0,0,0,2.391-.856l1.12,2.475A16.66,16.66,0,0,1,109.943,250.967Zm-20.008-.446-1.96-1.882a14.334,14.334,0,0,1,2.341-2l1.512,2.258A11.613,11.613,0,0,0,89.935,250.52Zm11.113-1.285a16.323,16.323,0,0,0-2.374-.994l.817-2.592a18.864,18.864,0,0,1,2.767,1.154Zm14.5-.959-1.859-1.982a8.247,8.247,0,0,0,1.431-1.9l2.355,1.355A10.778,10.778,0,0,1,115.548,248.276Zm-21.613-.36-.7-2.626a8.463,8.463,0,0,1,2.535-.279c.234.008.466.024.692.043l-.226,2.707q-.28-.023-.575-.035A5.634,5.634,0,0,0,93.935,247.916Zm24.829-4.839-2.509-1.045c.3-.725.609-1.531.965-2.541l2.563.9C119.408,241.453,119.085,242.305,118.763,243.078Zm1.932-5.337-2.57-.879c.312-.917.626-1.818.948-2.673l2.542.96C121.3,235.978,121,236.852,120.7,237.741Zm1.962-5.033-2.43-1.216a10.865,10.865,0,0,1,1.764-2.647l1.969,1.873A8.348,8.348,0,0,0,122.657,232.708Zm3.194-3.512-1.412-2.321a10.521,10.521,0,0,1,2.942-1.234l.651,2.637A7.806,7.806,0,0,0,125.851,229.2Zm6.77-.518a7.147,7.147,0,0,0-2.262-.583l.237-2.706a9.871,9.871,0,0,1,3.12.8Z" transform="translate(-79.42 -225.389)" fill="#fff" />
        </g>
        <path id="Path_376" data-name="Path 376" d="M150.113,225.361l-5.4,2.54-2.583-5.5-2.732,1.284,2.583,5.5-5.4,2.54,1.306,2.778,5.4-2.54,2.583,5.5,2.732-1.284-2.583-5.5,5.4-2.54Z" transform="translate(-78.828 -199.833)" fill="#fff" />
      </g>
    </svg>

  )
}

export const OrangeCheckMarks = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="_" data-name=" " transform="translate(-542.531 -304.054)" style={{isolation : "isolate"}}>
        <g id="Group_6037" data-name="Group 6037" style={{isolation : "isolate"}}>
          <path id="Path_4994" data-name="Path 4994" d="M550.762,325.763q1.8-4.044,3.876-7.8a75.687,75.687,0,0,1,4.468-7.134,38.918,38.918,0,0,1,3.655-4.629,8.164,8.164,0,0,1,1.743-1.467,10.063,10.063,0,0,1,2.132-.445,19.539,19.539,0,0,1,2.263-.232c.259,0,.389.1.389.3a.578.578,0,0,1-.139.362,8.873,8.873,0,0,1-.807.77,51.581,51.581,0,0,0-7.385,9.2,72.058,72.058,0,0,0-6.735,12.784,20.053,20.053,0,0,1-1.5,3.21q-.41.557-2.579.557-1.559,0-1.865-.316a15.564,15.564,0,0,1-1.234-1.725,30.749,30.749,0,0,0-3.507-4.453q-1-1.058-1-1.6,0-.741,1.1-1.512a3.449,3.449,0,0,1,1.821-.77,4.129,4.129,0,0,1,2.284,1.02A13.5,13.5,0,0,1,550.762,325.763Z" fill="#f77d26" />
        </g>
      </g>
    </svg>
  )
}

export const GreenComplete = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"  width="30" height="30" viewBox="0 0 48 47">
    <g id="Group_6134" data-name="Group 6134" transform="translate(-580 -668)">
      <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(580 668)" fill="#93ff8e" stroke="#1cc700" strokeWidth="3">
        <ellipse cx="24" cy="23.5" rx="24" ry="23.5" stroke="none" />
        <ellipse cx="24" cy="23.5" rx="22.5" ry="22" fill="none" />
      </g>
      <path id="Path_4683" data-name="Path 4683" d="M43.966,743.449a46.4,46.4,0,0,1,10.865,10.02c3.157-6.218,8.713-15.262,21.272-18.85a48.718,48.718,0,0,1-4.086-7.342c-6.886,3.063-14.65,9.05-17.737,15.747-3.351-3.788-6.737-7.343-6.737-7.343Z" transform="translate(543.465 -48.873)" fill="#93ff8e" stroke="#1cc700" strokeMiterlimit="10" strokeWidth="2" />
    </g>
  </svg>
  )
}

export const CompleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 48 47">
      <g id="Group_5655" data-name="Group 5655" transform="translate(-580 -668)">
        <g id="Ellipse_7" data-name="Ellipse 7" transform="translate(580 668)" fill="#fff" stroke="#656464" strokeWidth="3">
          <ellipse cx="24" cy="23.5" rx="24" ry="23.5" stroke="none" />
          <ellipse cx="24" cy="23.5" rx="22.5" ry="22" fill="none" />
        </g>
        <path id="Path_4683" data-name="Path 4683" d="M42.966,743.449a41.3,41.3,0,0,1,11.865,10.02c3.157-6.218,8.713-15.262,21.272-18.85a48.718,48.718,0,0,1-4.086-7.342c-6.886,3.063-14.65,9.05-17.737,15.747-3.351-3.788-6.737-7.343-6.737-7.343Z" transform="translate(543.465 -48.873)" fill="#fff" stroke="#656464" strokeMiterlimit="10" strokeWidth="2" />
      </g>
    </svg>
  )
}

export const FlyingShip = () => {
  return (
    <svg id="Group_5850" data-name="Group 5850" xmlns="http://www.w3.org/2000/svg" width="242.651" height="108.838" viewBox="0 0 242.651 108.838">
      <path id="Path_4983" data-name="Path 4983" d="M317.879,360.985h-4.372a76.474,76.474,0,1,0-152.949,0h-4.372a80.847,80.847,0,1,1,161.693,0Z" transform="translate(-115.706 -280.138)" fill="#656464" />
      <path id="Path_4984" data-name="Path 4984" d="M298.8,359.488h-4.372a58.891,58.891,0,1,0-117.782,0h-4.372a63.263,63.263,0,1,1,126.527,0Z" transform="translate(-114.209 -278.64)" fill="#656464" />
      <path id="Path_4985" data-name="Path 4985" d="M340.111,356.12h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H329.18v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466H309.5Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H294.2v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466H278.9Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H254.85v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H237.36v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466H230.8Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H215.5v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466H200.2Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H180.52v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0H167.4v-5.466h2.186Zm-4.372,0H163.03v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466H152.1Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-2.186v-5.466h2.186Zm-4.372,0h-1.54v-5.466h1.54Z" transform="translate(-117.451 -273.573)" fill="#96999c" />
      <path id="Path_4986" data-name="Path 4986" d="M359.833,316.511l-98.325,34.924a19.274,19.274,0,0,0-2.574-5.668L285.8,329.658c.265-.18.5-.489.268-1.265-.242-.8-.589-1.032-.875-.881l-30.912,13.4A19.277,19.277,0,0,0,240.7,337.3l-11.251-35.223a.735.735,0,0,0-.906-.527c-.627.068-.705.831-.63,1.141l7.9,35.825a19.273,19.273,0,0,0-10.575,10.115l-33.481,3.738c-.321.011-.509.385-.32,1.2.183.789.541.939.861.964l31.315.627a19.29,19.29,0,0,0,.6,6.283l0,.008L120.373,380.235a1.332,1.332,0,0,0-1.177,1.738c.2,1.019,1.042,1.11,1.827,1.041l80.177-8.946a8.867,8.867,0,1,0,14.667-1.636l14.125-1.576a19.3,19.3,0,0,0,31.283-8.53l12.235-5.3a8.864,8.864,0,1,0,13.464-5.836l73.678-31.94c.715-.33,1.4-.826,1.068-1.81A1.333,1.333,0,0,0,359.833,316.511Z" transform="translate(-119.153 -278.145)" fill="#1cc700" />
    </svg>
  )
}

