import React from 'react';

const ThriveHistoryIcon = () => (<svg xmlns="http://www.w3.org/2000/svg" width="22.08" height="21.877" viewBox="0 0 22.08 21.877">
  <g id="Group_6827" data-name="Group 6827" transform="translate(0.01)">
    <path id="Path_6298" data-name="Path 6298" d="M11.5,2.017A6.861,6.861,0,0,0,5.99,6.157c2.507-3.7,15.1-3.265,20.282-3.186A7.232,7.232,0,0,0,27.471,0C24.523,1.036,16.78,1.283,11.5,2.017Z" transform="translate(-5.401)" fill="#1E88E5" />
    <path id="Path_6299" data-name="Path 6299" d="M3.643,37.959A5.733,5.733,0,0,0-.01,40.847c3.653-1.472,12.483-3.3,18.7-2.085A5.9,5.9,0,0,0,20.7,36.678C12.8,36.458,8.548,36.487,3.643,37.959Z" transform="translate(0 -32.923)" fill="#1E88E5" />
    <path id="Path_6300" data-name="Path 6300" d="M49.763,59.671C49.429,63.285,47.981,72.7,43.3,75.082a8.522,8.522,0,0,0,2.487.172s3.976.213,6.684-15.924A13.41,13.41,0,0,0,49.763,59.671Z" transform="translate(-38.983 -53.402)" fill="#1E88E5" fillRule="evenodd" />
    <path id="Path_6301" data-name="Path 6301" d="M42.583,63.624c-.268,3.435-2.118,11.109-5.173,15.06,3.481-2.1,5.332-7.156,6.408-15.224A8.5,8.5,0,0,0,42.583,63.624Z" transform="translate(-33.681 -57.119)" fill="#1E88E5" fillRule="evenodd" />
  </g>
</svg>);

export default ThriveHistoryIcon;
