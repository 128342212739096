export const courseType = {
  'Warm-Up': {
    courses: [],
    showButtons: false,
  },
  'Follow Along': {
    showButtons: true,
    buttons: ['Mobility', 'Core', 'Handstand', 'Conditioning', 'Strength'],
  },
  'Foundation': {
    courses: [],
    showButtons: false,
  },
  'Skill': {
    courses: [],
    showButtons: false,
  },
  'Push': {
    showButtons: true,
    buttons: ['Level one', 'Level two', 'Level three', 'Level four'],
  },
  'Pull': {
    showButtons: true,
    buttons: ['Level one', 'Level two', 'Level three', 'Level four'],
  },
  'Core': {
    showButtons: true,
    buttons: ['Level one', 'Level two', 'Level three', 'Level four'],
  },
  'Legs': {
    showButtons: true,
    buttons: ['Level one', 'Level two', 'Level three', 'Level four'],
  },
  'Mobility': {
    showButtons: true,
    buttons: ['Level one', 'Level two', 'Level three', 'Level four'],
  }
}
