import Axios from 'axios'
import { showToast } from './calendarActions'
import { getCalanderDate } from '../../Components/UtilComponents/GetCurrentWeek'
import _ from 'lodash';
import * as Sentry from "@sentry/react";

import * as actions from './actionTypes';

const API = process.env.REACT_APP_API;

// check if all items in array are the same
const areAllSame = (arr) => {
  const first = arr[0];
  return arr.every(item => item === first) ? first : 1;
}

// param arr of bools that will return a bool
const areAllSameBool = (arr) => {
  const first = arr[0];
  return arr.every(item => item === first) ? first : false;
}

export const fetchFreeMember = () => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  const config = {
    method: 'get',
    url: `${API}/auto-pilot/view/weekly/users/${UserId}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  };

  Axios(config).then(response => {

    const res = response.data;
    const objectKeys = Object.keys(res.dayView);
    for (let i = 0; i < objectKeys.length; i++) {

      let workoutData = res.dayView[objectKeys[i]];

      if (workoutData && workoutData.exerciseListForDay && workoutData.exerciseListForDay.length > 0) {
        let rounds = workoutData.exerciseListForDay.map(prog => prog.rounds);
        let isAllLogged = workoutData.exerciseListForDay.map(prog => prog.isLogged);
        let round = areAllSame(rounds);
        isAllLogged = areAllSameBool(isAllLogged);

        res.dayView[objectKeys[i]] = {
          ...res.dayView[objectKeys[i]],
          rounds: round,
          isLogged: isAllLogged,
        }
      }
    }

    dispatch({
      type: actions.SET_FREE_MEMBER,
      payload: {
        ...res,
      },
    })
    dispatch(getAllSavedWorkouts());
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const updateProgress = (count, autoPilotId, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { dayView } = state.freeMember;
  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;


  const index = workoutData.findIndex(w => w.autoPilotId === autoPilotId);
  const toUpdate = workoutData[index];

  toUpdate.repsOrSecs = count;

  workoutData[index] = toUpdate;

  newDayView[dateKey].exerciseListForDay = workoutData

  dispatch({
    type: actions.EDIT_PROG,
    payload: { dayView: newDayView },
  });
}


export const updateRounds = (rounds, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { dayView } = state.freeMember;
  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;

  let newWorkoutData = workoutData.map(item => { return { ...item, rounds: rounds } });

  newDayView[dateKey].exerciseListForDay = newWorkoutData;
  newDayView[dateKey].rounds = rounds;

  dispatch({
    type: actions.EDIT_ROUNDS,
    payload: {
      dayView: newDayView,
    },
  });
}

export const resetSingleProg = (autoPilotId, dateKeyIndex, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[dateKeyIndex];

  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;
  const rounds = newDayView[dateKey].rounds



  const config = {
    method: 'put',
    url: `${API}/auto-pilot/refresh/one/users/${UserId}/apId/${autoPilotId}?date=${weekDay}&rounds=${rounds}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  };

  Axios(config).then(response => {
    const index = workoutData.findIndex(w => w.autoPilotId === autoPilotId);

    workoutData[index] = response.data[0];
    newDayView[dateKey].exerciseListForDay = workoutData;

    dispatch({
      type: actions.RESET_SINGLE_PROG,
      payload: {
        dayView: newDayView
      },
    });
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const resetAllProg = (dateKeyIndex, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[dateKeyIndex];
  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;
  const newWorkoutData = _.cloneDeep(workoutData);
  const rounds = newDayView[dateKey].rounds

  let catArry = workoutData.filter(item => !item.isLogged).map(item => item.autoPilotId);

  const config = {
    method: 'put',
    url: `${API}/auto-pilot/refresh/all/users/${UserId}?date=${weekDay}&rounds=${rounds}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
    data: catArry
  };

  Axios(config).then(response => {

    // newDayView[dateKey].exerciseListForDay = response.data;

    response.data?.forEach(item => {
      const index = workoutData.findIndex(w => w.autoPilotId === item.autoPilotId);
      newWorkoutData[index] = item;
    });

    newDayView[dateKey].exerciseListForDay = newWorkoutData;
    dispatch({
      type: actions.RESET_ALL_PROG,
      payload: {
        dayView: newDayView
      },
    });
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const markAllDone = (dateKeyIndex, dateKey) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[dateKeyIndex];
  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;
  const rounds = newDayView[dateKey].rounds

  let catArry = workoutData.map(item => {
    return {
      autoPilotId: item.autoPilotId,
      secsOrReps: item.repsOrSecs,
      rounds
    }
  });

  const config = {
    method: 'put',
    url: `${API}/auto-pilot/mark-alldone/users/${UserId}?date=${weekDay}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
    data: catArry
  };

  Axios(config).then(response => {
    let newWorkoutData = workoutData.map(item => { return { ...item, isLogged: true } });
    newDayView[dateKey].exerciseListForDay = newWorkoutData;

    dispatch({
      type: actions.MARK_ALL_PROG,
      payload: {
        dayView: newDayView
      },
    })
  }
  ).catch(error => {
    Sentry.captureException(error);
  });
}

export const logIndivdualDays = (autopilotId, dateKey, repsOrSec) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;
  const { dayView } = state.freeMember;
  const newDayView = _.cloneDeep(dayView);
  const workoutData = newDayView[dateKey].exerciseListForDay;
  const rounds = newDayView[dateKey].rounds

  const config = {
    method: 'put',
    url: `${API}/auto-pilot/log/users/${UserId}/apId/${autopilotId}?rounds=${rounds}&secsOrReps=${repsOrSec}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    }
  };

  Axios(config).then(response => {
    const index = workoutData.findIndex(w => w.autoPilotId === autopilotId);
    workoutData[index].isLogged = true;
    newDayView[dateKey].exerciseListForDay = workoutData;

    // checks if all are now loagged
    const first = workoutData[0].isLogged;
    const allComplete = workoutData.every(item => item.isLogged === first) ? true : false;

    newDayView[dateKey].isLogged = allComplete;

    dispatch({
      type: actions.LOG_INDIVIDUAL_DAYS,
      payload: {
        dayView: newDayView
      },
    });
  }).catch(error => {
    Sentry.captureException(error);
  });

}

export const deleteCategory = (autoPilotId, currentDateIndex, currentDate) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[currentDateIndex]
  const workoutData = dayView[currentDate].exerciseListForDay;

  if (workoutData.length <= 1) {
    dispatch({ type: actions.SHOW_TOAST, message: 'Minimum of one is category required.', variation: 'success' })
    setTimeout(() => {
      dispatch({ type: actions.HIDE_TOAST });
    }, 2500)
  }
  else {
    //  http://api-test.gymnasticbodies.com/auto-pilot/category/users/348441/id/11?date=2021-08-12
    const config = {
      method: 'delete',
      url: `${API}/auto-pilot/category/users/${UserId}/id/${autoPilotId}?date=${weekDay}`,
      headers: {
        'Authorization': `Bearer ${webToken}`,
      },
    };

    Axios(config).then(response => {
      const newArray = workoutData.filter(w => w.autoPilotId !== autoPilotId);
      let newDayView = _.cloneDeep(dayView);
      newDayView[currentDate].exerciseListForDay = newArray;
      dispatch({
        type: actions.REMOVE_SINGLE_PROG,
        payload: {
          dayView: newDayView
        },
      });
      dispatch(showToast('Category Deleted', 'success'));
    }).catch(error => {
      Sentry.captureException(error);
    });
  }
}

export const addNewCategory = (name, currentDateIndex, currentDate) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[currentDateIndex]
  const rounds = dayView[currentDate].rounds
  let workoutData = dayView[currentDate].exerciseListForDay;

  // http://api-test.gymnasticbodies.com/auto-pilot/category/users/348441?category=Mobile&rounds=3&date=2021-08-12

  const config = {
    method: 'post',
    url: `${API}/auto-pilot/category/users/${UserId}?category=${name}&rounds=${rounds}&date=${weekDay}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  };


  Axios(config).then(response => {
    let newDayView = _.cloneDeep(dayView);
    let newWorkoutData = [...workoutData, response.data[0]];
    newDayView[currentDate].exerciseListForDay = newWorkoutData;


    dispatch({
      type: actions.ADD_SINGLE_PROG,
      payload: {
        dayView: newDayView
      },
    });

    // dispatch(showToast('New Category Added!', 'success'));

  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const generateWorkout = (genDateIndex, genDate) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;

  const weekDays = getCalanderDate(timezone, 'YYYY-MM-DD');


  const config = {
    method: 'post',
    url: `${API}/auto-pilot/generate/users/${UserId}?date=${weekDays[genDateIndex]}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  };

  Axios(config).then(response => {
    const { data } = response;
    let newDayView = _.cloneDeep(dayView);
    let rounds = data.map(prog => prog.rounds);

    newDayView[genDate] = {
      exerciseListForDay: data,
      isLogged: false,
      favoriteId: null,
      rounds: areAllSame(rounds),
    };

    dispatch({
      type: actions.ADD_SINGLE_PROG,
      payload: {
        dayView: newDayView
      },
    });
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const loadPreviousDay = (genDateIndex, genDate) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;

  const weekDays = getCalanderDate(timezone, 'YYYY-MM-DD')[genDateIndex];


  const config = {
    method: 'post',
    url: `${API}/auto-pilot/copy-previous/users/${UserId}?date=${weekDays}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  };

  Axios(config).then(response => {
    const { data } = response;
    if (data === "There is no previous day's workout") {
      dispatch(showToast('There is no previous day\'s workout', 'error'));
    }
    else {
      let newDayView = _.cloneDeep(dayView);
      let rounds = data.map(prog => prog.rounds);

      newDayView[genDate] = {
        exerciseListForDay: data,
        isLogged: false,
        favoriteId: null,
        rounds: areAllSame(rounds),
      };
      dispatch({
        type: actions.ADD_SINGLE_PROG,
        payload: {
          dayView: newDayView
        },
      });
      dispatch(showToast('Previous Day Loaded!', 'success'));
    }
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const saveWorkout = (currentDateIndex, title, description) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const weekDay = getCalanderDate(timezone)[currentDateIndex];

  const config = {
    method: 'post',
    url: `${API}/auto-pilot/favorites/users/${UserId}?date=${weekDay}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
    data: {
      userId: UserId,
      title: title,
      description: description,
      dateCreated: weekDay
    }
  }
  Axios(config).then(response => {
    dispatch(fetchFreeMember());
    // dispatch(showToast('Workout Saved!', 'success'));
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const getAllSavedWorkouts = () => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  const config = {
    method: 'get',
    url: `${API}/auto-pilot/favorites/all/users/${UserId}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  }
  Axios(config).then(response => {
    dispatch({
      type: actions.GET_ALL_SAVED_WORKOUTS,
      payload: { savedWrokout: response.data },
    })
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const deleteSavedWorkout = (id) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId } = state.login;

  const config = {
    method: 'delete',
    url: `${API}/auto-pilot/users/${UserId}/favorites/${id}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  }
  Axios(config).then(response => {
    dispatch(fetchFreeMember());
    dispatch(showToast('Workout Deleted!', 'success'));
  }).catch(error => {
    Sentry.captureException(error);
  });
}

export const loadSavedWorkout = (id, currentDateIndex, currentDate) => (dispatch, getState) => {
  const state = getState();
  const { webToken, UserId, timezone } = state.login;
  const { dayView } = state.freeMember;
  const weekDay = getCalanderDate(timezone)[currentDateIndex]

  const config = {
    method: 'post',
    url: `${API}/auto-pilot/favorites/${id}/users/${UserId}?date=${weekDay}`,
    headers: {
      'Authorization': `Bearer ${webToken}`,
    },
  }
  Axios(config).then(response => {
    const { data } = response;
    let newDayView = _.cloneDeep(dayView);
    let rounds = data.map(prog => prog.rounds);

    newDayView[currentDate] = {
      exerciseListForDay: data,
      isLogged: false,
      favoriteId: null,
      rounds: areAllSame(rounds),
    };

    dispatch({
      type: actions.ADD_SINGLE_PROG,
      payload: {
        dayView: newDayView
      },
    });
    // dispatch(showToast('Workout Loaded!', 'success'));
  }).catch(error => {
    Sentry.captureException(error);
  });
}
