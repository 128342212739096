export const mainCourses = [
  {
    courseName: 'Foundation',
    imgUrl: 'foundation.png',
    associatedCourses: [
      {
        name: 'Foundation 1',
        nameId: 'F1',
        imgUrl: 'foundation1.jpg',
      },
      {
        name: 'Foundation 2',
        nameId: 'F2',
        imgUrl: 'foundation2.jpg',
      },
      {
        name: 'Foundation 3',
        nameId: 'F3',
        imgUrl: 'foundation3.jpg',
      },
      {
        name: 'Foundation 4',
        nameId: 'F4',
        imgUrl: 'foundation4.jpg',
      }
    ],
  },
  {
    courseName: 'Handstand',
    imgUrl: 'handstand.png',
    associatedCourses: [
      {
        name: 'Handstand 1',
        nameId: 'H1',
        imgUrl: 'handstand1.jpg',
      },
      {
        name: 'Handstand 2',
        nameId: 'H2',
        imgUrl: 'handstand2.jpg',
      },
      {
        name: 'Handstand 3',
        nameId: 'H3',
        imgUrl: 'handstand3.jpg',
      },
    ],
  },
  {
    courseName: 'Stretch',
    imgUrl: 'stretch.png',
    hideReps: true,
    associatedCourses: [
      {
        name: 'Stretch Series - Middle Split',
        nameId: 'SMS',
        imgUrl: 'stretchseriesmiddlesplit.jpg',
      },
      {
        name: 'Stretch Series - Front Split',
        nameId: 'SFS',
        imgUrl: 'stretchseriesfrontsplit.jpg',
      },
      {
        name: 'Stretch Series - Thoracic Bridge',
        nameId: 'STB',
        imgUrl: 'stretchseriesthoracicbridge.jpg',
      },
    ],
  },
  {
    courseName: 'Movement',
    imgUrl: 'movement.png',
    nameId: 'M',
  },
  {
    courseName: 'Rings',
    imgUrl: 'rings.png',
    nameId: 'R',
  }
]
