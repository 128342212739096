import React from 'react'

export const equipment = [
  {
    title: 'Foundation One & Level One',
    equipment: ['Elevated Surface', 'Free Weights', 'Dip Apparatus', <a href="https://gear.gymnasticbodies.com/" rel="noopener noreferrer" target="_blank">Rings</a>, <a href="https://amzn.to/2MxUl4U" rel="noopener noreferrer" target="_blank">Chin-up Bar</a>]
  },
  {
    title: 'Foundation Two & Level Two',
    equipment: ['Elevated Surface', 'Free Weights', 'Parallel Bars', <a href="https://gear.gymnasticbodies.com/" rel="noopener noreferrer" target="_blank">Rings</a>, <a href="https://amzn.to/2MxUl4U" rel="noopener noreferrer" target="_blank">Chin-up Bar</a>, 'Stabilized Item to grip on the ground', 'Stall Bars', <a href="https://amzn.to/2KOx1Om" rel="noopener noreferrer" target="_blank">Resistance Band</a>]
  },
  {
    title: 'Foundation Three & Level Three',
    equipment: ['Parallel Bars', <a href="https://amzn.to/2Zm0EKK" rel="noopener noreferrer" target="_blank">Parallettes</a>, 'Free Weights', 'Weighted Bar', <a href="https://amzn.to/2L2Xqqs" rel="noopener noreferrer" target="_blank">Rope</a>, <a href="https://gear.gymnasticbodies.com/" target="_blank" rel="noopener noreferrer">Back Strap</a>]
  },
  {
    title: 'Foundation Four & Level Four',
    equipment: ['Parallel Bars', 'High Bar', <a href="https://amzn.to/2Zm0EKK" rel="noopener noreferrer" target="_blank">Parallettes</a>, 'Free Weights', 'Weighted Bar', <a href="https://amzn.to/2L2Xqqs" rel="noopener noreferrer" target="_blank">Rope</a>, <a href="https://gear.gymnasticbodies.com/" target="_blank" rel="noopener noreferrer">Back Strap</a>]
  },
  {
    title: 'Handstand',
    multiCourse: [{
        title: 'Handstand One',
        equipment: ['None']
      },
      {
        title: 'Handstand Two',
        equipment: ['Elevated Surfaces', 'Stall Bars', <a href="https://amzn.to/2Zm0EKK" rel="noopener noreferrer" target="_blank">Low Parallel Bars or Parallettes</a>]
      },
      {
        title: 'Handstand Three',
        equipment: [<a href="https://amzn.to/2Zm0EKK" rel="noopener noreferrer" target="_blank">Parallettes</a>, <a href="https://amzn.to/2KOx1Om" rel="noopener noreferrer" target="_blank">Resistance Band</a>, 'Support (for one-handed balance)']
      }
    ]
  },
  {
    title: 'Stretch Series',
    multiCourse: [{
        title: 'Middle Split Stretch & Front Split Stretch',
        equipment: ['Yoga Blocks']
      },
      {
        title: 'Thoracic Bridge',
        equipment: ['Yoga Blocks', <a href="https://amzn.to/2KOx1Om" rel="noopener noreferrer" target="_blank">Resistance Band</a>, 'Workout Ball']
      }
    ]
  },
  {
    title: 'Movement',
    equipment: ['Panel Mat']
  },
  {
    title: 'Rings',
    equipment: [<a href="https://gear.gymnasticbodies.com/" rel="noopener noreferrer" target="_blank">Rings</a>, <a href="https://amzn.to/2KOx1Om" rel="noopener noreferrer" target="_blank">Resistance Band</a>]
  },
  {
    title: ' Bands',
    equipment: [<a href="https://amzn.to/2KOx1Om" rel="noopener noreferrer" target="_blank">Resistance Band</a>]
  },
  {
    title: ' Restore Series',
    equipment: ['Bench/Chair', <a href="https://amzn.to/2MxUl4U" rel="noopener noreferrer" target="_blank">Chin-up Bar</a>, 'Dowel', 'Yoga Blocks']
  }
]
